import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      cricketHill: file(relativePath: { regex: "/cricket-hill.jpg/" }) {
        childImageSharp {
          sizes(maxWidth: 1240) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `)

  return (
          <Img
            style={{ width: "100%", height: "100%" }}
            title="Performance at Cricket Hill Brewery"
            alt="Performance at Cricket Hill Brewery"
            sizes={data.cricketHill.childImageSharp.sizes}
          />

  )
}