import React from "react";
import { IndexPageProps } from "../lib/";
import CricketHillImage from "./CricketHillImage";
import PageLayout from "./PageLayout";

export default ({}: IndexPageProps) => (
  <PageLayout>
    <CricketHillImage />
  </PageLayout>
);
